import type { IconProps } from "./SvgIcon";
import SvgIcon from "./SvgIcon";

export default function CheckIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      paths={
        <path fill="currentColor" d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
      }
    />
  );
}
